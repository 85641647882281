const binding = { modules: {}, dataActions: {} };

    (binding.modules['tile-list'] = {
        c: () => require('@msdyn365-commerce-modules/tile-list/dist/lib/modules/tile-list/tile-list'),
        $type: 'containerModule',
        da: [],
        
        iNM: true,
        ns: '@msdyn365-commerce-modules',
        n: 'tile-list',
        p: 'tile-list',
        
        pdp: '',
        
        
        md: 'node_modules/@msdyn365-commerce-modules/tile-list/dist/lib/modules/tile-list'
    });
        

        
    window.__bindings__ = window.__bindings__ || {};
    window.__bindings__.modules = {
        ...window.__bindings__.modules || {},
        ...binding.modules
    };
    
        window.__bindings__.dataActions = {
        ...window.__bindings__.dataActions || {},
        ...binding.dataActions
    };
                        export const viewDictionary = {};
                        viewDictionary['@msdyn365-commerce-modules|tile-list|modules|tile-list|tile-list'] = {
                c: () => require('@msdyn365-commerce-modules/tile-list/dist/lib/modules/tile-list/tile-list.view.js'),
                cn: '@msdyn365-commerce-modules-tile-list-tile-list'
            };
window.__bindings__ = window.__bindings__ || {};
window.__bindings__.viewDictionary = {
                        ...window.__bindings__.viewDictionary || {},
                        ...viewDictionary
                    };